import React from "react";
import Hero from "./Hero";

function HomePage() {
  return (
    <>
      <div className="pt-64" />
      <Hero />
    </>
  );
}

export default HomePage;
